import React from "react";

import styled, { createGlobalStyle } from "styled-components";

import { Layout } from "../components/Layout";
import { breakpoints } from "../styledResponsive";

const AboutText = styled.div`
  /* background-color: green; */
  width: 100%;
  height: 350px;
`;

const TextBox = styled.div`
  margin: auto;
  /* background-color: red; */
  display: flex;
  flex-direction: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${breakpoints("width", ["90%", "90%", "600px", "600px"])}
`;
const Text2 = styled.p`
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  color: #aaa;
  letter-spacing: 2px;
  margin-bottom: 24px;
  margin-top: 150px;
`;
const InputGroup = styled.div`
  margin-bottom: 40px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  ${breakpoints("width", ["90%", "90%", "600px", "600px"])}
`;

const InputLabel = styled.label`
  display: block;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const InputControl = styled.input`
  background-color: transparent;
  border: 1px solid;
  font-size: 18px;
  max-width: 100%;
  min-width: 100%;
  padding: 15px 0;
  text-indent: 15px;
`;

const InputControlTextArea = styled.textarea`
  background-color: transparent;
  border: 1px solid;
  height: 60px;
  font-size: 18px;
  max-width: 100%;
  min-width: 100%;
  padding: 15px 0;
  text-indent: 15px;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 150px;
`;

const Button = styled.button`
  background-color: #444;
  color: #fff;
  border: 0;
  padding: 15px 30px;
  width: 300px;
  margin: auto;
`;

const ContactPage = () => {
  return (
    <Layout>
      <>
        <AboutText>
          <TextBox>
            <Text2>
              Tem alguma dúvida ou precisa de mais informações? Preencha seus
              dados abaixo e deixe sua mensagem que em breve retornaremos o seu
              contato.
            </Text2>
          </TextBox>
        </AboutText>
        <InputGroup>
          <InputLabel>Nome*</InputLabel>
          <InputControl name="name" required />
        </InputGroup>
        <InputGroup>
          <InputLabel>E-mail*</InputLabel>
          <InputControl name="email" type="email" required />
        </InputGroup>
        <InputGroup>
          <InputLabel>Telefone</InputLabel>
          <InputControl name="phone" type="phone" />
        </InputGroup>
        <InputGroup>
          <InputLabel>Mensagem</InputLabel>
          <InputControlTextArea name="message" />
        </InputGroup>
        <ButtonContainer>
          <Button>Enviar</Button>
        </ButtonContainer>
      </>
    </Layout>
  );
};

export default ContactPage;
